import React, { useEffect } from "react";
import "./testimonials.css";
import googleLogo from "../../Assets/googleLogo.png"; // Replace with your Google logo path
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

export default function Testimonials() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Trigger animations only once
    });
  }, []);

  const testimonials = [
    {
      id: 1,
      name: "Abhishek Jangid (Jaipur)",
      review:
        "बहुत अच्छा अनुभव रहा। उनकी सेवा और समय पर डिलीवरी वास्तव में सराहनीय है।",
    },
    {
      id: 2,
      name: "Rahul Jangid (Ajmer)",
      review:
        "The quality of service was outstanding, and the team was highly professional.",
    },
    {
      id: 3,
      name: "Manish Suthar (Barmer)",
      review:
        "शानदार काम! उनकी डिटेल पर ध्यान देने की आदत और समय की पाबंदी ने मुझे प्रभावित किया।",
    },
    {
      id: 4,
      name: "Sneha Suthar (Udaipur)",
      review:
        "Highly reliable and skilled professionals. The team was cooperative and attentive to our needs.",
    },
    {
      id: 5,
      name: "Ankit Kumawat (Bhopal, MP)",
      review:
        "Outstanding experience! Their customer support and dedication were truly exceptional.",
    },
    {
      id: 6,
      name: "Nitesh Jangid (Jodhpur)",
      review:
        "उनकी सर्विस और कस्टमर सपोर्ट बहुत ही शानदार थी। मैं निश्चित रूप से उन्हें दोबारा हायर करूंगा।",
    },
    {
      id: 7,
      name: "Rohit Suthar (Mysore, Karnataka)",
      review:
        "The team exceeded our expectations. I’m impressed by their professionalism and quick delivery.",
    },
    {
      id: 8,
      name: "Pooja Kumawat (Indore, MP)",
      review:
        "बहुत ही उम्दा सेवा और ग्राहक समर्थन। मैं इन्हें सभी को सुझाव देती हूं।",
    },
    {
      id: 9,
      name: "Arun Jangid (Bengaluru, Karnataka)",
      review:
        "Professional team with an eye for detail. They delivered high-quality results efficiently.",
    },
    {
      id: 10,
      name: "Krishna Suthar (Thiruvananthapuram, Kerala)",
      review:
        "Great experience working with the team. Their technical knowledge is exceptional!",
    },
    {
      id: 11,
      name: "Anil Kumawat (Kota)",
      review:
        "उनकी टीम के साथ काम करना अद्भुत था। उनकी तकनीकी क्षमता और सेवा की गुणवत्ता शानदार है।",
    },
    {
      id: 12,
      name: "dashrath Jangid (Alwar)",
      review:
        "The service quality and quick response made the entire process seamless and hassle-free.",
    },
    {
      id: 13,
      name: "Jugraj Suthar (Pali)",
      review:
        "उनकी टीम ने बेहतरीन काम किया। ग्राहक की ज़रूरतों को समझने और समय पर डिलीवरी देने में बहुत सक्षम हैं।",
    },
    {
      id: 14,
      name: "Mohit Jangid (Thrissur, Kerala)",
      review:
        "Amazing attention to detail and very client-focused approach. Highly recommend!",
    },
    {
      id: 15,
      name: "Rohit Kumawat (Ujjain, MP)",
      review:
        "शानदार अनुभव! टीम ने हर एक डिटेल पर ध्यान दिया और मेरी सभी उम्मीदों को पूरा किया।",
    },
  ];

  return (
    <section className="tst-mn-sc-mn">
      <div className="testimonials-container">
        <h1 data-aos="fade-up">Client Testimonials</h1>
        <p data-aos="fade-up">
          Hear what our clients say about us. Reviews sourced directly from{" "}
          <strong>Google</strong>.
        </p>
        <div className="testimonials-grid">
          {testimonials.map((testimonial) => (
            <div
              data-aos="fade-up"
              key={testimonial.id}
              className="testimonial-card"
            >
              <div className="testimonial-header">
                <img
                  src={googleLogo}
                  alt="Google Logo"
                  className="google-logo"
                />
                <h3>{testimonial.name}</h3>
              </div>
              <p>"{testimonial.review}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
