import React from "react";
import { Helmet } from "react-helmet"; // Importing React Helmet
import Hero from "../Components/About/Hero";
import Overview from "../Components/About/Overview";
import History from "../Components/About/History";
import Location from "../Components/Home/Location";
import FAQ from "../Components/Home/FAQ";

export default function About() {
  return (
    <>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>About Us - Mach Mart</title>
        <meta
          name="description"
          content="Learn more about our company, our journey, values, and the people behind the success. We are committed to delivering excellence."
        />
        <meta
          name="keywords"
          content="About Us, Company Information, Team, Values, Mission"
        />
        <meta name="author" content="Mach Mart" />
        <meta property="og:title" content="About Us - Mach Mart" />
        <meta
          property="og:description"
          content="Discover our journey, values, and the team dedicated to delivering top-notch solutions."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* About Page Hero Section */}
      <Hero />
      <Overview />
      <History />
      <Location />
      <FAQ/>
    </>
  );
}
