import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./posts.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  // Fetch posts from the API
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_POSTS); // Replace with your API endpoint
        const data = await response.json();
        console.log(data.posts);

        if (response.ok) {
          setPosts(data.posts); // Set posts state with real data
        } else {
          console.error("Failed to fetch posts:", data);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <>
      <Helmet>
        <title>Posts - Mach Mart</title>
        <meta
          name="description"
          content="Explore the latest updates, insights, and articles from Mach Mart. Stay informed about industry trends and our journey."
        />
        <meta
          name="keywords"
          content="Posts, Updates, Articles, Industry Insights, Mach Mart News"
        />
        <meta name="author" content="Mach Mart" />
        <meta property="og:title" content="Posts - Mach Mart" />
        <meta
          property="og:description"
          content="Stay updated with the latest posts, news, and insights from Mach Mart. Discover valuable content crafted by our team."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <section className="posts-container">
        <div className="posts-wrapper">
          {posts.length > 0 ? (
            posts.map((post) => (
              <div key={post._id} className="post-card">
                <div className="post-image-container">
                  <img
                    src={post.image || "https://via.placeholder.com/1400x400"} // Use placeholder if no image
                    alt={post.heading}
                    className="post-image"
                  />
                  <div className="post-overlay">
                    <p className="post-overlay-text">
                      By {post.author || "Mach Mart"} |{" "}
                      {new Date(post.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <h3 className="post-title">{post.title}</h3>
                <p className="post-content">
                  {post.intro.length > 100
                    ? `${post.intro.slice(0, 100)}...`
                    : post.intro}
                </p>
                <span
                  onClick={() => {
                    navigate(`/post/${post._id}`); // Redirect to the individual post page
                  }}
                  className="post-button"
                >
                  Read more{" "}
                  <i
                    style={{ marginLeft: "4px" }}
                    className="fa-solid fa-turn-down fa-sm"
                  ></i>
                </span>
              </div>
            ))
          ) : (
            <p>Loading posts...</p> // Show loading message if posts are not yet fetched
          )}
        </div>
      </section>
    </>
  );
}
