import React, { useEffect } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

export default function Footer() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Allow animation to repeat
    });
  }, []);

  return (
    <>
      <section className="FooterSection">
        <div className="mainDivision_FooterSection">
          <div className="navManu_Divs addressDivCom_Footer">
            <div className="addressDiv">
              <h1 >Mach Mart</h1>
              <p >
                Plot No. H726B, Road No. 9F2, Viwhwakarma Industrial Area
              </p>
              <p >Jaipur, Rajasthan, India </p>
              <span className="span_FollowUs">Follow Us On </span>{" "}
              <div className="socialMediaHandles_Footer">
                <a
                  target="blank"
                  href={"https://www.instagram.com/machmartjaipur/"}
                >
                  <i className="fa-brands fa-instagram fa-xl"></i>
                </a>
                <a
                  href="https://www.instagram.com/machmartjaipur/"
                  target="blank"
                  to={"/"}
                >
                  <i className="fa-brands fa-facebook fa-xl"></i>
                </a>
                <a href="https://wa.me/918503886992" target="blank" to={"/"}>
                  <i className="fa-brands fa-whatsapp fa-xl"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/machmartjaipur"
                  target="blank"
                  to={"/"}
                >
                  <i className="fa-brands fa-linkedin fa-xl"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/machmartjaipur"
                  target="blank"
                  to={"/"}
                >
                  <i className="fa-brands fa-pinterest fa-xl"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
                  target="blank"
                  to={"/"}
                >
                  <i className="fa-brands fa-youtube fa-xl"></i>
                </a>
              </div>
              <span>
                Contact No.: <a href="tel:8503886992">8503886992</a>,{" "}
                <a href="tel:7849912491">7849912491</a>
              </span>
              <span>
                Email:{" "}
                <a href="mailto:machmartjaipur@gmail.com">sales@machmart.in</a>
              </span>
            </div>
          </div>
          <div className="navManu_Divs">
            <ul>
              <li>Quick Links</li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/"}
                >
                  Homepage
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/posts"}
                >
                  Gallary
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/contact"}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div className="navManu_Divs">
            <ul>
              <li>Machinery</li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/machinery"}
                >
                  Combined Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/machinery"}
                >
                  Surface Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/machinery"}
                >
                  Thickness Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/machinery"}
                >
                  Chain Mortiser
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/machinery"}
                >
                  Vertical Band Saw
                </Link>
              </li>
            </ul>
          </div>
          <div className="navManu_Divs">
            <ul>
              <li>Company Profile</li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  About Company
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  From Owner/Founder
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  Career
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/contact"}
                >
                  Work With Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="copyrightDiv_Footer">
        <div className="mainDivCopyright">
          <p>
            <i className="fa-regular fa-copyright"></i> Copyright 2024-2025 Mach
            Mart
          </p>
          <p>
            Website Designed and Maintained by{" "}
            <a target="blank" href="https://ventureitsolutions.in/">
              Venture IT Solutions
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
