import React, { useEffect } from "react";
import "./hero.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bgImage from "../../Images/homeHeroBGImage.jpg";
import logo from "../../Assets/logoMachMart.png";

export default function Hero() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
    <>
      <section className="mn-sc">
        <section className="mn-sc-abt-pg-hr">
          {/* Left Section */}
          <div className="left-section">
            {/* Top 70% Image */}
            <div className="top-image">
              <img src={bgImage} alt="Sample" />
            </div>
            {/* Bottom 30% Content */}
            <div className="bottom-content" data-aos="fade-up">
              <div className="social-icons">
                {/* Social Media Icons */}
                <Link
                  target="blank"
                  to={"https://www.instagram.com/machmartjaipur/"}
                >
                  <i className="fa-brands fa-instagram fa-lg"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/machmartjaipur/"
                  target="blank"
                >
                  <i className="fa-brands fa-facebook fa-lg"></i>
                </Link>
                <Link to="https://wa.me/918503886992" target="blank">
                  <i className="fa-brands fa-whatsapp fa-lg"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/machmartjaipur"
                  target="blank"
                >
                  <i className="fa-brands fa-linkedin fa-lg"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/x360-marketing"
                  target="blank"
                >
                  <i className="fa-brands fa-pinterest fa-lg"></i>
                </Link>
                <Link
                  to="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
                  target="blank"
                >
                  <i className="fa-brands fa-youtube fa-lg"></i>
                </Link>
              </div>
              <h2 data-aos="fade-up">About Us</h2>
              <p data-aos="fade-up">
                At Mach Mart, we are dedicated to transforming ideas into
                impactful results. Our team combines innovation with expertise
                to deliver tailored IT solutions that drive business growth and
                efficiency. With a focus on quality and customer satisfaction,
                we empower organizations to stay ahead in an ever-evolving
                digital landscape. Whether it's software development, CRM
                systems, or cutting-edge marketing tools, we are here to partner
                with you on your journey to success.
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className="right-section">
            {/* Top 30% - Logo */}
            <div className="logo">
              <img data-aos="fade-up" src={logo} alt="Logo" />
            </div>
            {/* Bottom 70% - Heading */}
            <div className="heading" data-aos="fade-up">
              <h1>Your Partner in Progress: Innovate, Deliver, Succeed</h1>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
