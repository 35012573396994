import React, { useEffect } from "react";
import "./whychooseus.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles
import { Link } from "react-router-dom";

export default function WhyChooseUs() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true, // Animations happen once
    });
  }, []);

  return (
    <section className="why-chs-us-mn-sc">
      <div className="hd-dv" data-aos="fade-up">
        <h1>
          Why Choose Us for{" "}
          <span style={{ color: "#e5302f" }}>Your Machinery Needs?</span>
        </h1>
        Mach Mart offers premium woodworking machinery built for precision,
        reliability, and exceptional performance. Our advanced,{" "}
        <span style={{ color: "#e5302f" }}>
          durable machines are designed to optimize productivity,{" "}
        </span>{" "}
        ensuring long-term value and high-quality results for all your
        woodworking projects.
      </div>
      <div className="why-chs-us-mn-sc-cntr">
        <div className="left-side">
          <div className="top">
            <div
              className="top-left"
              data-aos="fade-up"
              data-aos-delay="100" // Staggered animation for top-left section
            >
              <i className="fa-solid fa-cogs"></i> {/* Example Icon */}
              <h3>Quality Machinery</h3>
              <p>
                Mach Mart provides top-quality woodworking machinery including
                CNC routers, wood planers, and band saws, delivering precision,
                durability, and high performance for all your woodworking needs.
              </p>
            </div>
            <div
              className="top-right"
              data-aos="fade-up"
              data-aos-delay="200" // Staggered animation for top-right section
            >
              <i className="fa-solid fa-gear"></i> {/* Example Icon */}
              <h3>Advanced Technology</h3>
              <p>
                Mach Mart’s woodworking machinery, including CNC routers and
                edge banding machines, integrates cutting-edge technology for
                enhanced precision, efficiency, and automation, ensuring optimal
                performance and superior results.
              </p>
            </div>
          </div>
          <div className="bottom">
            <div
              className="bottom-content"
              data-aos="fade-up"
              data-aos-delay="300" // Staggered animation for bottom section
            >
              <i className="fa-solid fa-thumbs-up"></i> {/* Example Icon */}
              <h3>Customer Satisfaction</h3>
              <p>
                At Mach Mart, we prioritize customer satisfaction by offering
                high-quality woodworking machinery backed by exceptional
                service, reliability, and support to meet your specific needs.
              </p>
            </div>
          </div>
        </div>
        <div
          className="right-side-hm"
          data-aos="fade-up"
          data-aos-delay="400" // Staggered animation for right-side section
        >
          <div className="content-hm">
            <i className="fa-solid fa-rocket"></i> {/* Example Icon */}
            <h3>Why Us?</h3>
            <p>
              We provide top-quality products with excellent customer service
              and support.
            </p>
            <Link target="blank" to={"https://wa.me//918503886992"}>
              <button className="ts-btn ts-enquiry-btn">Purchase Now </button>
            </Link>
            <Link to={"/contact"}>
              {" "}
              <button className="ts-btn ts-enquiry-btn">Enquiry Now</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
