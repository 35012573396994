import React, { useState, useEffect } from "react";
import "./hero.css"; // Import the styles
import AOS from "aos";
import "aos/dist/aos.css";

export default function Hero() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    requirement: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration
      once: true, // Animation happens only once
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, mobile, requirement } = formData;

    // Validation
    if (!mobile || !/^[6-9]\d{9}$/.test(mobile)) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }

    // Clear previous messages
    setError("");
    setSuccess("");

    try {
      const response = await fetch(process.env.REACT_APP_ADD_LEAD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName || "NA",
          lastName: lastName || "NA",
          mobile,
          requirement: requirement || "NA",
        }),
      });

      if (response.ok) {
        setSuccess("Enquiry submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          mobile: "",
          requirement: "",
        });
      } else {
        setError("Failed to submit enquiry. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while submitting the form.");
    }
  };

  return (
    <>
      <section className="mn-sc-cntc-hr">
        <div className="hero-content">
          {/* Left Section */}
          <div className="hero-left" data-aos="fade-right">
            <h1>
              Welcome to <span>Mach Mart</span>, where we bring you the latest
              and most reliable <span>machinery</span> solutions.
            </h1>
            <p>
              Your one-stop destination for quality machinery solutions. Explore
              our products and services.
            </p>
            <button className="cta-button">Call Now</button>
          </div>

          {/* Right Section (Enquiry Form) */}
          <div className="hero-right" data-aos="fade-left">
            <form className="enquiry-form" onSubmit={handleSubmit}>
              <h1 data-aos="fade-up">Enquiry Now</h1>
              {error && <p className="error-message">{error}</p>}
              {success && <p className="success-message">{success}</p>}
              <div className="form-row" data-aos="fade-up">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="form-input"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="form-input"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile"
                className="form-input"
                value={formData.mobile}
                onChange={handleChange}
                required
                data-aos="fade-up"
              />
              <textarea
                name="requirement"
                placeholder="Your Requirement"
                className="form-input"
                value={formData.requirement}
                onChange={handleChange}
                data-aos="fade-up"
              ></textarea>
              <button
                type="submit"
                className="submit-button"
                data-aos="fade-up"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
