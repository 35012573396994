import React, { useEffect } from "react";
import "./faq.css";
import AOS from "aos"; // Importing AOS
import "aos/dist/aos.css"; // AOS styles

export default function FAQ() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 400, // Duration of the animation in milliseconds
      once: true, // Animation happens only once when scrolled into view
    });
  }, []);

  const faqData = [
    {
      id: 1,
      title: "What types of woodworking machines does Mach Mart manufacture?",
      content:
        "Mach Mart manufactures a wide variety of woodworking machines, including Chain Mortisers, Planner Machines, Combined Machines, Thickness Planner Machines, Vertical Band Saw Machines, Horizontal Band Saw Machines, and many more. We specialize in high-performance machines tailored for precision, efficiency, and durability.",
    },
    {
      id: 2,
      title:
        "How long has Mach Mart been in the woodworking machinery industry?",
      content:
        "Mach Mart has been manufacturing woodworking machines for over 35 years. With decades of experience, we have built a reputation for delivering high-quality, reliable machines that meet the diverse needs of woodworking businesses.",
    },
    {
      id: 3,
      title: "What is the pricing structure for woodworking services?",
      content:
        "The cost of woodworking services at Mach Mart varies depending on the complexity and scope of your project. We offer free consultations and detailed quotes to ensure that you get an accurate estimate. Our pricing is transparent and competitive, and we are committed to delivering value for every project.",
    },
    {
      id: 4,
      title: "What is a Chain Mortiser and how is it used?",
      content:
        "A Chain Mortiser is a woodworking machine used to create precise mortises in wood. It’s commonly used for joinery, making it ideal for carpentry, door, and window manufacturing. Mach Mart’s Chain Mortisers are designed for high precision and durability, ensuring smooth operations and consistent results.",
    },
    {
      id: 5,
      title:
        "What is the difference between a Vertical and Horizontal Band Saw Machine?",
      content:
        "A Vertical Band Saw Machine cuts wood vertically, making it perfect for detailed work and complex shapes. A Horizontal Band Saw Machine, on the other hand, is used for cutting thicker pieces of wood in a horizontal direction. Mach Mart offers both types, ensuring versatility and reliability for different types of wood cutting jobs.",
    },
  ];

  return (
    <>
      <section className="faq-sc-mn">
        <h1>FAQ's</h1>
        <p>Mach Mart: Your Trusted Woodworking Machine Manufacturer</p>
        <div className="mn-dv-faq-sc">
          <div className="accordion" id="accordionFlushExample">
            {faqData.map((faq) => (
              <div className="accordion-item" key={faq.id}>
                <h2 className="accordion-header hd-acr">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${faq.id}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${faq.id}`}
                  >
                    {faq.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${faq.id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{faq.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
