import React, { useState, useEffect } from "react";
import "./machinery.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function Machinery() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredMachinery, setFilteredMachinery] = useState([]);
  const [machines, setMachines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API
    const fetchProducts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_PRODUCTS);
        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }
        const data = await response.json();
        setMachines(data.products); // Assuming `data.products` is the array of products
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Filter products based on search and category
    const filterData = machines.filter(
      (item) =>
        (!selectedCategory || item.category === selectedCategory) &&
        (!searchQuery ||
          item.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredMachinery(filterData);
  }, [selectedCategory, searchQuery, machines]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Helmet>
        <title>Machinery - Mach Mart</title>
        <meta
          name="description"
          content="Explore our wide range of machinery. Discover top-notch equipment to meet your industrial needs with detailed specifications and categories."
        />
        <meta
          name="keywords"
          content="Machinery, Industrial Equipment, Mach Mart, Categories, Heavy Machines"
        />
        <meta name="author" content="Mach Mart" />
        <meta property="og:title" content="Machinery - Mach Mart" />
        <meta
          property="og:description"
          content="Browse through our extensive catalog of industrial machinery, sorted by categories, with advanced search and filtering options."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="mchnry-mn-sc">
        <div className="mchnry-container">
          {/* Search and Filter */}
          <div className="mchnry-controls">
            <input
              type="text"
              placeholder="Search machinery..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="mchnry-search"
            />
            <select
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="mchnry-filter"
            >
              <option value="">All Categories</option>
              {/* Dynamically generate categories */}
              {[...new Set(machines.map((machine) => machine.category))].map(
                (category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                )
              )}
            </select>
          </div>

          {/* Machinery List */}
          <div className="mchnry-list">
            {filteredMachinery.map((machine) => (
              <div key={machine._id} className="mchnry-card">
                <img
                  src={machine.images[0]}
                  alt={machine.name}
                  onError={(e) => (e.target.src = "fallback-image.png")} // Fallback in case of missing image
                />
                <h4>{machine.name}</h4>
                <p>Category: {machine.category}</p>
                <p>Price: {machine.price}</p>
                <button
                  onClick={() => {
                    navigate(`/machinery/${machine._id}`);
                  }}
                >
                  View Details
                </button>
                <button className="whatsappbtn">Whatsapp</button>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
