import React from "react";
import Hero from "../Components/Home/Hero";
import Featured from "../Components/Home/Featured";
import Topselling from "../Components/Home/Topselling";
import Whychooseus from "../Components/Home/Whychooseus";
import Clients from "../Components/Home/Clients";
import Testimonials from "../Components/Home/Testimonials";
import Location from "../Components/Home/Location";
import FAQ from "../Components/Home/FAQ";
import { Helmet } from "react-helmet";
export default function Home() {
  return (
    <>
      <Helmet>
        <title>Mach Mart - All types of Wood Working Machines</title>
        <meta
          name="description"
          content="Learn more about our company, our journey, values, and the people behind the success. We are committed to delivering excellence."
        />
        <meta
          name="keywords"
          content="About Us, Company Information, Team, Values, Mission"
        />
        <meta name="author" content="Mach Mart" />
        <meta property="og:title" content="Mach Mart - All types of Wood Working Machines" />
        <meta
          property="og:description"
          content="Discover our journey, values, and the team dedicated to delivering top-notch solutions."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Hero />
      <Topselling />
      <Featured />
      <Whychooseus />
      <Clients />
      <Testimonials />
      <Location />
      <FAQ />
    </>
  );
}
