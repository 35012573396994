import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../Components/Contact/Hero";
import Location from "../Components/Home/Location";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us - Mach Mart</title>
        <meta
          name="description"
          content="Get in touch with us to learn more about our products, services, and how we can help your business grow. We are here to assist you."
        />
        <meta
          name="keywords"
          content="Contact Us, Get in Touch, Customer Support, Inquiry, Mach Mart"
        />
        <meta name="author" content="Mach Mart" />
        <meta property="og:title" content="Contact Us - Mach Mart" />
        <meta
          property="og:description"
          content="Have questions? Reach out to us today for assistance with our products and services."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Hero />
      <Location />
    </>
  );
}
