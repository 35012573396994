import React, { useEffect, useState } from "react";
import "./navbar.css";
import brandLogo from "../../Assets/logoMachMart.png";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigate = useNavigate();

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 50) {
        setShowNavbar(false); // Hide navbar on scroll down
      } else {
        setShowNavbar(true); // Show navbar on scroll up
      }
      setLastScrollY(window.scrollY); // Update the last scroll position
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up event listener
    };
  }, [lastScrollY]);

  return (
    <>
      <section
        className={`Navbar_MainSection ${
          showNavbar ? "navbar-show" : "navbar-hide"
        }`}
      >
        {/* Upper Section */}
        <section className="Navbar_Section_Upper">
          <div className="mainDivision_NavbarSection_Upper">
            <div className="textMachinery_NavbarUpperSection">
              <h3>All Types of Wood Working Machinery Available Here</h3>
            </div>
            <div className="socialmediaIcons_Navbar">
              <Link
                target="_blank"
                to="https://www.instagram.com/machmartjaipur/"
              >
                <i className="fa-brands fa-instagram fa-lg"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/machmartjaipur/"
              >
                <i className="fa-brands fa-facebook fa-lg"></i>
              </Link>
              <Link target="_blank" to="https://wa.me/918503886992">
                <i className="fa-brands fa-whatsapp fa-lg"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/machmartjaipur"
              >
                <i className="fa-brands fa-linkedin fa-lg"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
              >
                <i className="fa-brands fa-youtube fa-lg"></i>
              </Link>
            </div>
          </div>
        </section>

        {/* Middle Section with logo and contact */}
        <section className="NavbarSection_Both">
          <section className="Navbar_Section_Middle">
            <div className="mainDivision_Navbar_Middle_Division">
              <div className="logoDiv_Navbar">
                <Link to="/">
                  <img src={brandLogo} alt="Mach Mart Logo" />
                </Link>
              </div>
              <div className="contactDetailsDiv_Navbar">
                <p>
                  <i className="fa-solid fa-location-dot"></i> Vishwakarma
                  Industrial Area, Jaipur, Rajasthan
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i>{" "}
                  <Link href="tel:8503886992">8503886992</Link>,{" "}
                  <Link href="tel:9828259289">9828259289</Link>
                </p>
              </div>
            </div>
          </section>

          {/* Lower Section with links */}
          <section className="Navbar_Section_Lower">
            <div className="mainDivision_Navbar_Section_Lower">
              <ul>
                <li>
                  <Link to="/">Homepage</Link>
                </li>
                <li>
                  <Link to="/machinery">Machinery</Link>
                </li>
                <li>
                  <Link to="/posts">Gallery</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>
            </div>
          </section>
        </section>

        <div className="hamburger-icon">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={brandLogo}
            alt="Mach Mart Logo"
          />
          {/* Hamburger or Cross Icon */}
          <i
            onClick={toggleMenu}
            className={`fa-solid ${menuOpen ? "fa-xmark" : "fa-bars"}`}
          ></i>{" "}
        </div>
      </section>
      {/* Mobile Menu Section */}
      <div className={`mobile-menu ${menuOpen ? "open" : ""}`}>
        <ul className="mobile-links">
          <li>
            <Link onClick={toggleMenu} to="/">
              Homepage
            </Link>
          </li>
          <li>
            <Link onClick={toggleMenu} to="/machinery">
              Machinery
            </Link>
          </li>
          <li>
            <Link onClick={toggleMenu} to="/posts">
              Gallery
            </Link>
          </li>
          <li>
            <Link onClick={toggleMenu} to="/contact">
              Contact
            </Link>
          </li>
          <li>
            <Link onClick={toggleMenu} to="/about">
              About
            </Link>
          </li>
        </ul>
        <div className="mobile-contact">
          <p>
            <i className="fa-solid fa-location-dot"></i> Vishwakarma Industrial
            Area, Jaipur, Rajasthan
          </p>
          <p>
            <i className="fa-solid fa-phone"></i>
            <Link href="tel:8503886992">8503886992,</Link>
            <Link href="tel:9828259289">9828259289</Link>
          </p>
          <div className="mobile-social">
            <Link
              target="_blank"
              href="https://www.instagram.com/machmartjaipur/"
            >
              <i className="fa-brands fa-instagram"></i>
            </Link>
            <Link
              target="_blank"
              href="https://www.instagram.com/machmartjaipur/"
            >
              <i className="fa-brands fa-facebook"></i>
            </Link>
            <Link target="_blank" href="https://wa.me/919828259289">
              <i className="fa-brands fa-whatsapp"></i>
            </Link>
            <Link
              target="_blank"
              href="https://www.linkedin.com/company/x360-marketing"
            >
              <i className="fa-brands fa-linkedin"></i>
            </Link>
            <Link
              target="_blank"
              href="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
            >
              <i className="fa-brands fa-youtube"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
