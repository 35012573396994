import React, { useState, useEffect } from "react";
import "./LeadForm.css";

export default function LeadForm() {
  const [showForm, setShowForm] = useState(false);
  const [mobile, setMobile] = useState(""); // State for mobile number
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [error, setError] = useState(""); // State for error handling

  // Show the modal after 30 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 10000);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  const handleMobileChange = (e) => {
    setMobile(e.target.value); // Update mobile state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Regular expression for mobile number validation (e.g., Indian numbers: 10 digits)
    const mobileRegex = /^[6-9]\d{9}$/;

    if (!mobile) {
      alert("Mobile number is required");
      return;
    }

    if (!mobileRegex.test(mobile)) {
      alert("Please enter a valid 10-digit mobile number");
      return;
    }

    setLoading(true); // Set loading state to true when submitting

    try {
      // Send data to your API endpoint
      const response = await fetch(process.env.REACT_APP_ADD_LEAD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: mobile,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Thank you for your enquiry!");
        setShowForm(false); // Close the form after success
      } else {
        setError(data.message || "Something went wrong");
      }
    } catch (err) {
      setError("Thank you for your enquiry");
    } finally {
      setLoading(false); // Set loading state to false after request is finished
    }
  };

  return (
    <>
      {showForm && (
        <section className="lead-form-overlay">
          <div className="lead-form-container">
            <button
              className="lead-form-close-btn"
              onClick={() => setShowForm(false)}
            >
              &times;
            </button>
            <h2 className="lead-form-title">Enter Your Mobile Number</h2>
            <form className="lead-form" onSubmit={handleSubmit}>
              <input
                type="tel"
                className="lead-form-input"
                placeholder="Your Mobile Number"
                value={mobile}
                onChange={handleMobileChange}
                required
              />
              <button
                type="submit"
                className="lead-form-submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
            {error && <p className="error-message">{error}</p>}{" "}
            {/* Error message */}
          </div>
        </section>
      )}
    </>
  );
}
