import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./postPage.css"; // Import the CSS file
import { Helmet } from "react-helmet";

export default function PostPage() {
  const [post, setPost] = useState(null); // State to store the post data
  const { id } = useParams(); // Get the post ID from the URL

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_POSTS); // Fetch all posts
        const data = await response.json();

        if (response.ok) {
          // Find the post that matches the ID
          const foundPost = data.posts.find((post) => post._id === id);

          if (foundPost) {
            setPost(foundPost); // Set the specific post to state
          } else {
            console.error("Post not found");
          }
        } else {
          console.error("Failed to fetch posts:", data);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPost();
  }, [id]); // Run this effect whenever the ID changes

  if (!post) {
    return <p>Loading post...</p>; // Show loading message while fetching the post
  }

  return (
    <section className="sp-container">
      <Helmet>
        <title>{post.heading} | Mach Mart</title>
        <meta
          name="description"
          content={`Read our latest post on "${
            post.heading
          }". Learn more about ${post.heading.slice(0, 50)}...`}
        />
        <meta
          name="keywords"
          content="blog post, latest articles, modern UI design, post title"
        />
        <link rel="canonical" href={`https://yourwebsite.com/post/${id}`} />
      </Helmet>

      <div className="sp-wrapper">
        {/* Image */}
        <div className="sp-image">
          <img
            src={post.image || "https://via.placeholder.com/1400x400"}
            alt="Post Cover"
          />
        </div>
        {/* Main Heading */}
        <h1 className="sp-heading">{post.heading}</h1>
        {/* Introduction */}
        <p className="sp-intro">{post.intro}</p>
        {/* Subheadings with Content */}
        <div className="sp-content">
          {post.subHeadings.map((sub, index) => (
            <div key={index} className="sp-subsection">
              <h2 className="sp-subheading">{sub.title}</h2>
              <p className="sp-text">{sub.content}</p>
            </div>
          ))}
        </div>
        {/* Outro */}
        <div className="sp-outro">
          <p>{post.outro}</p>
        </div>
      </div>
    </section>
  );
}
