import React, { useState, useEffect } from "react";
import "./product.css";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Productpage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(process.env.REACT_APP_GET_PRODUCTS);

        const foundProduct = response.data.products.find(
          (item) => item._id === id
        ); // Find product by ID
        if (foundProduct) {
          setProduct(foundProduct);
        } else {
          setError("Product not found.");
        }
        setLoading(false);
      } catch (err) {
        setError("Failed to load product data.");
        setLoading(false);
      }
    };

    fetchProducts();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (product) {
    console.log(product);
    return (
      <>
        <section className="productpage-container">
          <Helmet>
            <title>
              {product.name} - {product.category} | Mach Mart
            </title>
            <meta
              name="description"
              content={`${product.name} is a ${
                product.category
              } available for ₹${
                product.price
              }. Features include ${product.keyFeatures.join(", ")}.`}
            />
            <meta
              name="keywords"
              content="machinery, advanced machines, high precision tools, industrial equipment"
            />
            <link
              rel="canonical"
              href={`https://yourwebsite.com/product/${id}`}
            />
          </Helmet>

          {/* Left Section */}
          <div className="productpage-left">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                {product.images.map((_, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : undefined}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {product.images.map((image, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={image}
                      className="d-block w-100"
                      alt={`Product ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>

          {/* Right Section */}
          <div className="productpage-right">
            <h1 className="product-name">{product.name}</h1>
            <p className="product-category">Category: {product.category}</p>
            <p className="product-price">Price: {product.price}</p>
            <p className="product-description">{product.description}</p>

            <ul className="product-features">
              {product.keyFeatures.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            <div className="product-actions">
              <button className="btn-enquiry">Enquiry Now</button>
              <button className="btn-whatsapp">Whatsapp Now</button>
            </div>
          </div>
        </section>

        <section className="mn-sc-product-desc">
          <div className="product-desc-container">
            <p className="product-desc-text">
              <strong>Overview:</strong>
              {product.overview}
              <br />
              <strong>Key Features:</strong>
              <ul>
                {product.keyFeatures.map((keyFeature, index) => (
                  <li key={index}>{keyFeature}</li>
                ))}
              </ul>
              <br />
              <strong>Benefits:</strong>
              <ul>
                {product.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <br />
              <strong>Specifications:</strong>
              <table className="product-desc-table">
                <tbody>
                  {product.specifications.map((specItem) => (
                    <tr key={specItem._id}>
                      <td>{specItem.spec}:</td>
                      <td>{specItem.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </p>
          </div>
        </section>
      </>
    );
  } else {
    return <>NA</>;
  }
}
