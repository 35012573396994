import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Home/Navbar";
import Footer from "./Components/Home/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Machinery from "./Pages/Machinery";
import Posts from "./Pages/Posts";
import Productpage from "./Components/Productpage";
import FloatingButtons from "./Components/FloatingButtons";
import LeadForm from "./Components/Leadform";
import Postpage from "./Components/Postpage";
import ScrollToTop from "./Pages/ScrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop /> <Navbar />
        <FloatingButtons />
        <LeadForm />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/machinery" element={<Machinery />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          {/* Product Page */}
          <Route path="/machinery/:id" element={<Productpage />} />
          {/* Post Page */}
          <Route path="/post/:id" element={<Postpage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
