import React, { useEffect } from "react";
import "./overview.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Overview() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration
      once: true, // Animation happens only once
    });
  }, []);

  return (
    <>
      <section className="cmp-ovr-vw-sc">
        <div className="container">
          {/* Main Heading */}
          <h1 className="main-heading" data-aos="fade-up">
            Company Overview
          </h1>
          <p className="intro-paragraph" data-aos="fade-up">
            Mach Mart is dedicated to delivering innovative, high-quality
            woodworking machinery, with a focus on sustainability, precision,
            and customer satisfaction, backed by years of industry expertise.
          </p>

          {/* Subsections */}
          <div className="subsections">
            {/* Subsection 1 */}
            <div data-aos="fade-up" className="subsection">
              <h3>Our Vision</h3>
              <ul>
                <li>
                  To be the global leader in woodworking and machine
                  manufacturing, delivering innovative and reliable solutions.
                </li>
                <li>
                  To enhance woodworking efficiency and sustainability with
                  cutting-edge machinery.
                </li>
              </ul>
              <p>
                We aim to revolutionize the woodworking industry with
                cutting-edge technology and reliable products.
              </p>
            </div>

            {/* Subsection 2 */}
            <div data-aos="fade-up" className="subsection">
              <h3>Our Mission</h3>
              <ul>
                <li>
                  To provide high-quality, precision-engineered machinery for
                  the woodworking industry.
                </li>
                <li>
                  To deliver cost-effective, sustainable solutions that empower
                  businesses.
                </li>
              </ul>
              <p>
                We focus on providing sustainable, precision-engineered machines
                to optimize woodworking operations.
              </p>
            </div>

            {/* Subsection 3 */}
            <div data-aos="fade-up" className="subsection">
              <h3>Our Values</h3>
              <ul>
                <li>
                  {" "}
                  Continuously developing advanced technology and products for
                  woodworking.
                </li>
                <li>
                  {" "}
                  Ensuring the highest standards in material and craftsmanship
                  for durability.
                </li>
              </ul>
              <p>
                We emphasize technological innovation and product quality to
                meet industry standards.
              </p>
            </div>

            {/* Subsection 4 */}
            <div data-aos="fade-up" className="subsection">
              <h3>Our Expertise</h3>
              <ul>
                <li>
                  Expertise in designing and manufacturing precision woodworking
                  machinery.
                </li>
                <li>
                  Customizable solutions for diverse woodworking needs,
                  enhancing productivity and quality.
                </li>
              </ul>
              <p>
                With years of experience, we offer tailored solutions for
                diverse woodworking needs, ensuring enhanced efficiency..
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
